import { isNetworkAvailable } from './isNetworkAvailable';

/**
 * This function subscribes to network status changes and calls the callback when the network status changes
 * @param callback A callback that will be called when the network status changes
 * @returns A function that can be called to unsubscribe from the network status changes
 */
export function subscribeToNetworkChange(callback: (onLine: boolean) => void) {
    const connection = (self.navigator as any)?.connection;

    connection?.addEventListener('change', listener);
    self.addEventListener('online', listener);
    self.addEventListener('offline', listener);

    return () => {
        connection?.removeEventListener('change', listener);
        self.removeEventListener('online', listener);
        self.removeEventListener('offline', listener);
    };

    function listener(e: Event) {
        let isConnected = false;
        switch (e.type) {
            case 'online':
                isConnected = true;
                break;
            case 'offline':
                isConnected = false;
                break;
            case 'change':
                isConnected =
                    (connection?.type && connection.type !== 'none') ?? isNetworkAvailable();
                break;
            default:
                isConnected = isNetworkAvailable();
                break;
        }
        callback(isConnected);
    }
}
