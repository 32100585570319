import type { CustomDataMap, PredefinedCheckpoint } from 'owa-analytics-types';

const PredefinedCheckpointValues: PredefinedCheckpoint[] = [
    'Code_S',
    'Code_E',
    'Data_S',
    'Data_E',
    'Network_S',
    'Network_E',
    'Render_S',
    'Render_E',
    'GQL_Link_S',
    'GQL_Link_E',
    'GQL_Bridge_S',
    'GQL_Bridge_E',
    'GQL_Bridge_Error',
];

export function getUnifiedPerfCustomWaterfall(datapointCustomDataMap: CustomDataMap) {
    // Match WF_Custom1 through WF_Custom15 or WF_ followed by a value in PredefinedCheckpointValues
    const checkpointColumns = Object.keys(datapointCustomDataMap).filter(key => {
        const matchesCustomRange = key.match(/WF_Custom(1[0-5]|[1-9])/);

        const matchesPredefinedCheckpoint = PredefinedCheckpointValues.some(
            checkpoint => key === `WF_${checkpoint}`
        );
        return matchesCustomRange || matchesPredefinedCheckpoint;
    });

    const perfCustomWaterfall = {} as CustomDataMap;
    for (const checkpointColumn of checkpointColumns) {
        if (!perfCustomWaterfall[checkpointColumn]) {
            perfCustomWaterfall[checkpointColumn] = datapointCustomDataMap[checkpointColumn];
        }
    }

    return perfCustomWaterfall;
}
