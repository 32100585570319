export function pickBy<T extends object>(
    object: T | null | undefined,
    predicate?: (value: T[keyof T], key: string) => unknown
): Partial<T> {
    return object
        ? Object.entries(object).reduce((acc, [key, value]) => {
              if (!predicate || predicate(value, key)) {
                  acc[key] = value;
              }
              return acc;
          }, {} as Partial<T>)
        : {};
}
