import {
    AriaCoreDatapoint,
    isClientVerboseQueryStringEnabled,
} from '../datapoints/AriaCoreDatapoint';
import { DatapointStatus } from 'owa-analytics-types';
import type { ErrorType } from 'owa-errors';
import type { InternalDatapointOptions, PerformanceDatapointType } from 'owa-analytics-types';
import { logDatapoint } from './logDatapoint';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { isRunningOnWorker } from 'owa-config/lib/isRunningOnWorker';

export async function logPerformanceDatapoint(
    datapoint: PerformanceDatapointType,
    status: DatapointStatus | undefined,
    errorType: ErrorType | 'general' | undefined,
    batchId?: number
) {
    if (isRunningOnWorker()) {
        datapoint = AriaCoreDatapoint.fromJSObject<PerformanceDatapointType>(datapoint);
    }

    const properties = datapoint.getProperties();

    properties.Status = status;
    if (status != DatapointStatus.Success && status != DatapointStatus.BackgroundSuccess) {
        properties.ErrorType = errorType;
    }

    if (
        (isClientVerboseQueryStringEnabled() || datapoint.getOptions().logVerbose) &&
        datapoint.waterfallTimings
    ) {
        properties.Waterfall = scrubForPii(JSON.stringify(datapoint.waterfallTimings));
    }

    if (datapoint.greyError) {
        const dpOptions = datapoint.getOptions() as InternalDatapointOptions;
        dpOptions.isGreyError = true;

        properties['message'] = datapoint.greyError.message;
        properties['stack'] = datapoint.greyError.stack;
    }

    // Returning is important so the errors can be handled by the main thread.
    return logDatapoint(datapoint, undefined /* overrideEventType */, batchId);
}
