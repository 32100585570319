import {
    addCommonDiagnosticProperties,
    type DiagnosticOverrides,
} from './utils/addCommonDiagnosticProperties';
import type { ApplicationInsights, IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';
import type { CustomDataMap, DataPointEventType, CoreAnalyticsOptions } from 'owa-analytics-types';
import type { AppInisightsTenants, CachedProperties } from './types/OneDsWrapperTypes';
import { getApp } from 'owa-config/lib/bootstrapOptions';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { getMsaDeviceTicket } from 'owa-config/lib/msaDeviceTicket';
import { trace } from 'owa-trace';
import { getCollectorUrl } from 'owa-analytics-utils';
import { convertTo1DSTypes } from './utils/convertTo1DSTypes';
import { trackEventFrequency } from './utils/trackEventFrequency';
import { getCachedSettings } from './settings/getCachedSettings';
import { sendEventTo1DS } from './utils/sendEventTo1DS';
import { initializeAppInsights } from './initializeAppInsights';
import { isRunningOnWorker } from 'owa-config/lib/isRunningOnWorker';

let insights: AppInisightsTenants = {};
let tenantInitializationPromise: {
    [tenant: string]: Promise<void> | null;
} = {};
let cachedProperties: CachedProperties = {};

/**
 * Using this for an investigation for event being duplicated
 */
const eventDuplicationCheck: string[] = [];

export async function logOneDSDatapoint({
    tenantToken,
    item,
    analyticsOptions,
    overrideEndpointUrl,
    skipCommonProperties = false,
    useUnifiedSchema = false,
    customDiagnosticsOptions = {},
}: {
    tenantToken: string;
    item: IExtendedTelemetryItem;
    analyticsOptions?: CoreAnalyticsOptions;
    overrideEndpointUrl?: string;
    skipCommonProperties?: boolean;
    useUnifiedSchema?: boolean;
    updateAppSettings?: boolean;
    customDiagnosticsOptions?: CustomDataMap;
}) {
    const endpointUrl = getEndpointUrlWithOverrides(analyticsOptions, overrideEndpointUrl);
    const diagnosticOverrides: DiagnosticOverrides = {
        // This is to check if the "getThreadName" is setting the correct value in common properties
        Worker: self?.name || 'Main',
        IsRunningInAWorker: isRunningOnWorker(),
    };

    // If the tenant is not initialized, initialize it
    if (!insights[tenantToken] && !tenantInitializationPromise[tenantToken]) {
        // We do not await the promise here so we can block other incoming events
        tenantInitializationPromise[tenantToken] = initializeAppInsights(
            insights,
            cachedProperties,
            endpointUrl,
            tenantToken,
            analyticsOptions,
            skipCommonProperties,
            useUnifiedSchema,
            diagnosticOverrides
        );
    }

    // Wait for the tenant to be initialized
    await tenantInitializationPromise[tenantToken];

    // Set the MSA ticket if it has changed. We don't have it at boot so it will always get added later in the session.
    const msaDeviceTicket = getMsaDeviceTicket();
    const msaDeviceTicketChanged =
        cachedProperties[tenantToken]?.msaDeviceTicket !== msaDeviceTicket;
    if (msaDeviceTicket && msaDeviceTicketChanged) {
        // This ticket is only available in Native
        insights[tenantToken].getPostChannel()?.setMsaAuthTicket(msaDeviceTicket);
        // Add traces so we can see when this is updated
        trace.info(
            ` Setting MSA Device Ticket (${msaDeviceTicket}) for tenant: ${tenantToken}`,
            'analytics'
        );
        cachedProperties[tenantToken].msaDeviceTicket = msaDeviceTicket;
        diagnosticOverrides.MsaDeviceTicketChanged = true;
    }

    // Convert the event to a format that 1DS can understand
    if (useUnifiedSchema && item.data) {
        item.data['UserInfo.Id'] = item.data.UserId?.toString();
        item.data['UserInfo.IdType'] = item.data.UserIdType?.toString();
        delete item.data.UserId;
        delete item.data.UserIdType;

        item.data = convertTo1DSTypes(item.data);
    }

    // Add app and host telemetry to the event
    if (!useUnifiedSchema && item.data) {
        if (!item.data.App) {
            item.data.App = getApp();
            trace.warn(` Event ${item.name} should contain a App field.`);
        }

        const hostTelemetry = scrubForPii(getCachedSettings()?.opxSessionInfo?.hostTelemetry);
        if (hostTelemetry) {
            item.data.HostTelemetry = hostTelemetry;
        }
    }

    if (!self.navigator.onLine) {
        diagnosticOverrides.Offline = true;
    }

    // Investigation for event duplication
    if (item.data?.EventName && item.data?.SequenceNumber) {
        const eventId = `${item.data?.EventName}-${item.data?.SequenceNumber}`;
        if (eventDuplicationCheck.includes(eventId)) {
            diagnosticOverrides.EventDuplicated = true;

            // Keep the array small to avoid affecting memory and performance
            if (eventDuplicationCheck.length > 25) {
                eventDuplicationCheck.unshift();
            }
        } else {
            eventDuplicationCheck.push(eventId);
        }
    }

    // Add diagnostics overrides and set endpoint url
    addCommonDiagnosticProperties(endpointUrl, item, diagnosticOverrides, customDiagnosticsOptions);

    // Send the event to 1DS
    sendEventTo1DS(insights[tenantToken], item);

    // Track the event frequency
    trackEventFrequency(item);
}

function getEndpointUrlWithOverrides(
    analyticsOptions?: CoreAnalyticsOptions,
    overrideEndpointUrl?: string
) {
    if (overrideEndpointUrl) {
        return overrideEndpointUrl;
    }

    if (analyticsOptions?.endpointUrlOverride === undefined) {
        // Override is not passed in analyticsOptions, hence use the fallback collector url.
        return getCollectorUrl(analyticsOptions?.startupInfo.forest);
    }

    return analyticsOptions.endpointUrlOverride === null
        ? undefined // Converting null to undefined, to use the default 1DS collector url.
        : analyticsOptions.endpointUrlOverride;
}

export function oneDSFlush() {
    for (const analytics of Object.values(insights) as ApplicationInsights[]) {
        if (analytics && analytics.isInitialized()) {
            try {
                analytics.getPostChannel().flush();
            } catch {}
        }
    }
}

export function addMsaAuthTicket(ticket: string) {
    for (const analytics of Object.values(insights)) {
        if (analytics) {
            try {
                analytics.getPostChannel().setMsaAuthTicket(ticket);
            } catch {}
        }
    }
}

export function createEvent(
    eventName: DataPointEventType,
    props?: CustomDataMap,
    time?: number
): IExtendedTelemetryItem {
    return {
        name: eventName,
        data: props ? JSON.parse(JSON.stringify(props)) : {},
        time: (time ? new Date(time) : new Date()).toISOString(),
    };
}

export function TEST_ONLY_reset_globals() {
    insights = {};
    tenantInitializationPromise = {};
    cachedProperties = {};
}
