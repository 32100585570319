import type { AnalyticsSchema, CommonProperties, CoreAnalyticsOptions } from 'owa-analytics-types';
import { getCommonProperties } from '../settings/getCommonProperties';
import { getThreadName } from 'owa-thread-config';

interface PropertiesPlugin {
    setProperty(name: string, value: string | number | boolean): void;
}

function addProperty(
    propertyManger: PropertiesPlugin,
    key: AnalyticsSchema,
    value: string | undefined | null
) {
    if (value) {
        propertyManger.setProperty(key, value);
    }
}

export async function addCommonProperties(
    propertyManager: PropertiesPlugin,
    analyticsOptions: CoreAnalyticsOptions
) {
    const commonProperties = await getCommonProperties(analyticsOptions);

    // Override thread specific values
    commonProperties.Thread = getThreadName();

    addCommonPropertiesOnCurrentThread(propertyManager, commonProperties);
}

export function addCommonPropertiesOnCurrentThread(
    propertyManager: PropertiesPlugin,
    commonProperties: CommonProperties
) {
    for (const [key, value] of Object.entries(commonProperties)) {
        addProperty(propertyManager, key, value);
    }
}
