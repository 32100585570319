import type { eOfflineValue, IOfflineListener } from '@microsoft/applicationinsights-common';
import type { IExtendedConfiguration, IPayloadData, IPlugin } from '@microsoft/1ds-analytics-js';
import type { DataContexts } from '@microsoft/1ds-privacy-guard-js';
import type { CoreAnalyticsOptions } from 'owa-analytics-types';
import type { DiagnosticOverrides } from './utils/addCommonDiagnosticProperties';
import type { AppInisightsTenants, CachedProperties } from './types/OneDsWrapperTypes';
import {
    type eStorageProviders,
    type IOfflineChannelConfiguration,
    OfflineChannel,
} from '@microsoft/applicationinsights-offlinechannel-js';
import { PrivacyGuardPlugin } from '@microsoft/1ds-privacy-guard-js';
import { ApplicationInsights, NRT_PROFILE } from '@microsoft/1ds-analytics-js';
import pako from 'pako';
import { getThreadName } from 'owa-thread-config';
import { trace } from 'owa-trace';
import getUserAgentString from 'owa-user-agent/lib/utils/getUserAgentString';
import { subscribeToNetworkChange } from 'owa-offline/lib/subscribeToNetworkChange';
import { getClientVersion } from 'owa-config/lib/bootstrapOptions';
import { getSessionId } from 'owa-config/lib/getSessionId';
import { getAnalyticsFlightsAndAppSettings } from './settings/getAnalyticsFlightsAndAppSettings';
import { getCachedSettings } from './settings/getCachedSettings';
import { getOfflineTelemetryDatabasePrefix } from './utils/getOfflineTelemetryDatabasePrefix';
import { addCommonProperties } from './utils/addCommonProperties';

/**
 * This will initialize the 1DS SDK for a new tenant when we receive an new event
 */
export async function initializeAppInsights(
    insights: AppInisightsTenants,
    cachedProperties: CachedProperties,
    endpointUrl: string | undefined,
    tenantToken: string,
    analyticsOptions?: CoreAnalyticsOptions,
    skipCommonProperties?: boolean,
    useUnifiedSchema?: boolean,
    diagnosticOverrides: DiagnosticOverrides | null = null
) {
    trace.info(
        ` ${
            insights[tenantToken] ? 'Updating existing' : 'Initializing new'
        } 1DS tenant. Tenant: ${tenantToken}`,
        'analytics'
    );

    if (diagnosticOverrides) {
        diagnosticOverrides.InitinalizingAnalyticsThread = getThreadName() ?? 'Unknown';
        diagnosticOverrides.InitinalizingAnalyticsTenant = tenantToken;
    }

    if (insights[tenantToken]) {
        cachedProperties[tenantToken].offlineListener?.unload();
        cachedProperties[tenantToken].unsubscribe?.();

        // Use timeout to allow internal oneds timers to complete
        const oldInsights = insights[tenantToken];
        setTimeout(() => oldInsights.unload(), 30000);
    }

    insights[tenantToken] = new ApplicationInsights();
    const config: IExtendedConfiguration = {
        instrumentationKey: tenantToken,
        endpointUrl,
        disableCookiesUsage: true, // This stops sending installId in the SDK section
        channelConfiguration: {
            payloadPreprocessor: gzipFunc,
        },
        enableWParam: true, // Set the "w" parameters so the 1ds collector can parse the web content when it comes from the web worker
        propertyConfiguration: {
            populateBrowserInfo: true,
            populateOperatingSystemInfo: true,
            userAgent: getUserAgentString(),
        },
    };

    let plugins: IPlugin[] | undefined = undefined;
    if (
        getAnalyticsFlightsAndAppSettings()?.enablePrivacyGuard ||
        analyticsOptions?.enablePrivacyGuard
    ) {
        const privacyGuard: PrivacyGuardPlugin = new PrivacyGuardPlugin();
        const sessionSettings = getCachedSettings()?.userConfiguration?.SessionSettings;

        const dataCtx: DataContexts = {
            UserName: sessionSettings?.UserDisplayName,
            UserAlias: sessionSettings?.UserPrincipalName?.split('@')[0],
            IgnoredNameParts: ['Microsoft', 'Dogfood'],
        };

        config.extensionConfig = {
            [privacyGuard.identifier]: {
                context: dataCtx,
                scanForUrls: false,
            },
        };
        plugins = [privacyGuard];
    }

    insights[tenantToken].initialize(config, plugins);
    insights[tenantToken].getPostChannel()?._setTransmitProfile(NRT_PROFILE);

    // Common properties should only be initialized once per tenant
    // If a column can change, it should be added to each event as a normal column
    await addOrUpdateCommonProperties(
        insights[tenantToken],
        analyticsOptions,
        skipCommonProperties,
        useUnifiedSchema,
        diagnosticOverrides ?? undefined
    );

    let offlineListener: IOfflineListener | undefined;
    let unsubscribe: (() => void) | undefined;
    if (getAnalyticsFlightsAndAppSettings()?.enableOfflineCaching) {
        // OfflineChannel is not initialized properly if added during initialization
        // so we add it here via updateCfg / addPlugin
        const offlineChannel = new OfflineChannel();
        const idbProvider: eStorageProviders.IndexedDb = 3;
        const offlineConfig: IOfflineChannelConfiguration = {
            providers: [idbProvider],
            storageKeyPrefix: getOfflineTelemetryDatabasePrefix(tenantToken),
        };
        insights[tenantToken].updateCfg({
            extensionConfig: { [offlineChannel.identifier]: offlineConfig },
        });
        insights[tenantToken].addPlugin(offlineChannel);

        // The client is responsible for telling the OfflineChannel when it is online vs. offline
        offlineListener = offlineChannel.getOfflineListener();
        const offlineValue: eOfflineValue.Offline = 2;
        const onlineValue: eOfflineValue.Online = 1;
        offlineListener?.setOnlineState(self.navigator.onLine ? onlineValue : offlineValue);
        unsubscribe = subscribeToNetworkChange((onLine: boolean) => {
            () => offlineListener?.setOnlineState(onLine ? onlineValue : offlineValue);
        });
    }

    cachedProperties[tenantToken] = {
        offlineListener,
        unsubscribe,
    };
}

function gzipFunc(payload: IPayloadData, cb: (data: IPayloadData) => void) {
    try {
        const dataToSend = pako.gzip(payload.data);
        const headers = payload.headers || {};
        headers['Content-Encoding'] = 'gzip';
        payload.headers = headers;
        payload.data = dataToSend;
        cb(payload);
    } catch (err) {
        // send original payload on error
        return cb(payload);
    }
}

async function addOrUpdateCommonProperties(
    appInsights: ApplicationInsights,
    analyticsOptions?: CoreAnalyticsOptions,
    skipCommonProperties?: boolean,
    useUnifiedSchema?: boolean,
    diagnosticOverrides?: DiagnosticOverrides
) {
    if (diagnosticOverrides) {
        diagnosticOverrides.CommonPropertiesStatus = 'PENDING';
    }

    try {
        const propertyManager = appInsights.getPropertyManager();
        if (!skipCommonProperties && !useUnifiedSchema && analyticsOptions) {
            await addCommonProperties(propertyManager, analyticsOptions);
        }

        const context = propertyManager.getPropertiesContext();
        if (context) {
            context.app.ver = getClientVersion();
            context.session.setId(getSessionId());
            context.web.domain = undefined;
            // We do not set the local deviceId because it can cause the network request to fail
            // if the formatting is not correct. We log it as a separate property instead.
            context.device.localId = undefined;
            if (analyticsOptions?.overrideOsVersion) {
                context.os.ver = analyticsOptions.overrideOsVersion;
            }
        }

        if (diagnosticOverrides) {
            diagnosticOverrides.CommonPropertiesStatus = 'SUCCESS';
        }
    } catch (e) {
        if (diagnosticOverrides) {
            diagnosticOverrides.CommonPropertiesStatus = 'ERROR';
            diagnosticOverrides.CommonPropertiesError = e?.message;
        }
    }
}
